import React, { useState } from "react";
import {
  DialogTitle,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Divider,
  FormControlLabel,
  Switch,
  Grid,
  Checkbox,
} from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import * as Yup from "yup";
import { FormProvider } from "./../../hooks/hook-form";
import "./_modals.scss";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "./../Input";
import ProfileImage from "./../ProfileImage";
import {
  getDepartments,
  updateDepartment,
  updateOperator,
} from "./../../slices/operaters";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import { Close } from "./../../assets";
import { useEffect } from "react";
import { serialize } from "object-to-formdata";
import { dirtyValues } from "../../utilities/DirtyFields";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import ReplyZone from "../../pages/Messaging/Container/ReplyZone";
import Messages from "../../pages/Messaging/Container/Messages";
import Select from "./../Select";
const AddOperator = ({ id, open, handleClose, data, ...rest }) => {
  const dispatch = useDispatch();
  const { newAvatar } = useSelector((state) => state.user);
  const [disabled, setDisabled] = React.useState(false);
  const { roles } = useSelector((state) => state.roles);
  const { t } = useTranslation();
  const { statusUpdateOperator, statusUpdateDepartment, departments } =
    useSelector((state) => state.operators);
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "white" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };
  const MainStyle = styled("div")(() => ({
    paddingTop: data.APP_BAR_MOBILE + 3,
  }));
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .notRequired("Email is required"),
    firstName: Yup.string().notRequired("First Name is required").trim(),
    lastname: Yup.string().notRequired("Last Name  is required").trim(),
    role: Yup.string().notRequired("role is required"),
    phone: Yup.string().notRequired("Phone number is required").trim(),
    password:
      disabled === false
        ? null
        : Yup.string()
            .trim()
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
              t(
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
              )
            ),
    emailNotif: Yup.boolean(),
  });

  const DepartmentSchema = Yup.object().shape({
    name: Yup.string().required("Name is required").trim(),
    description: Yup.string().required("Description is required").trim(),
  });

  const methods = useForm({
    resolver: yupResolver(
      data?.id === "edit-department" ? DepartmentSchema : LoginSchema
    ),
  });

  useEffect(() => {
    methods.reset();
  }, [disabled]);

  useEffect(() => {
    data?.id === "edit-department"
      ? (() => {
          let defaultValues = {};
          defaultValues.name = data?.name;
          defaultValues.description = data?.description;
          methods.reset({ ...defaultValues });
        })()
      : (() => {
          let defaultValues = {};
          defaultValues.email = data?.email;
          defaultValues.firstName = data?.firstName;
          defaultValues.lastName = data?.lastName;
          defaultValues.phone = data?.phone;
          defaultValues.avatar = data?.avatar;
          defaultValues.role = data?.role?.code;
          defaultValues.department = data?.department?._id;
          defaultValues.emailNotif =
            data?.emailNotif === undefined ? false : data?.emailNotif;
          methods.reset({ ...defaultValues });
        })();
  }, [data]);
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    departments?.length === 0 &&
      user?.websiteID !== undefined &&
      dispatch(getDepartments({ websiteID: user?.websiteID, page: 1 }));
  }, [user]);

  useEffect(() => {
    register("phone", { required: true });
  }, []);
  const {
    handleSubmit,
    formState: { isSubmitting },
    register,
    watch,
  } = methods;
  const dirtyFields = methods?.formState.dirtyFields;
  const onSubmit = async (values) => {
    try {
      const dirtyVals = dirtyValues(dirtyFields, values);
      Object.keys(dirtyVals).map(function (key) {
        if (dirtyVals[key].length === 0) {
          delete dirtyVals[key];
        }
      });
      const SerlizedData = serialize({
        ...dirtyVals,
        file: newAvatar,
        emailNotif:
          values?.emailNotif !== user?.emailNotif
            ? values?.emailNotif
            : undefined,
      });
      data?.id === "edit-department"
        ? dispatch(
            updateDepartment({
              websiteID: user.websiteID,
              id: data?._id,
              data: dirtyVals,
            })
          ).then((res) => {
            res?.error
              ? showSnackbar({ variant: "error", message: res?.error?.message })
              : (() => {
                  methods.reset();
                  showSnackbar({
                    variant: "success",
                    message: "Department updated successfully",
                  });
                  handleClose(id);
                })();
          })
        : dispatch(
            updateOperator({
              websiteID: user.websiteID,
              id: data?._id,
              data:
                newAvatar !== null
                  ? SerlizedData
                  : {
                      ...dirtyVals,
                      emailNotif: values?.emailNotif,
                    },
            })
          ).then((res) => {
            res?.error
              ? showSnackbar({ variant: "error", message: res?.error?.message })
              : (() => {
                  methods.reset();
                  showSnackbar({
                    variant: "success",
                    message: res?.payload?.message,
                  });
                  handleClose(id);
                })();
          });
    } catch (err) {
      console.error(err.message);
    }
  };

  return (
    <Drawer
      anchor={localStorage.getItem("i18nextLng") === "ar" ? "left" : "right"}
      open={open}
      onClose={() => handleClose(id)}
      PaperProps={{
        sx: {
          width: "35%",
          height: "100%",
        },
      }}
    >
      <DialogTitle className="drawer-title">
        {data?.id === "drop-message"
          ? t("Send message")
          : data?.id === "edit-department"
          ? t("Edit Department")
          : t("update operator")}
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose(id);
          }}
          sx={{
            ml: 1,
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent",
            },
          }}
        >
          <LazyLoadImage src={Close} alt={"Close"} />
        </IconButton>
      </DialogTitle>
      <Divider />
      {data?.id !== "edit-department" && (
        <ProfileImage
          btnClassName={"uploadBtn"}
          userImage={data?.avatar}
          className="add-operator-image"
          id={
            data?.id === "drop-message" ? "drop-message" : "add-operator-drawer"
          }
          data={data}
        />
      )}

      {data?.id !== "drop-message" ? (
        <Accordion
          style={{ height: "100%", border: "none", boxShadow: "none" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {" "}
            <Typography>{t("Basic Information")}</Typography>
          </AccordionSummary>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {data?.id === "edit-department" ? (
              <div className="operator-inputs-wrapper">
                <div className="fistname-lastname-email-wrapper">
                  <Input
                    label={t("Name")}
                    placeholder={t("Name")}
                    name="name"
                    errors={methods.formState.errors}
                    register={register}
                  />
                  <Input
                    label={t("Description")}
                    placeholder={t("Description")}
                    name="description"
                    errors={methods.formState.errors}
                    register={register}
                  />
                </div>
              </div>
            ) : (
              <Grid container spacing={3} className="operator-inputs-wrapper">
                {/* <div className="fistname-lastname-email-wrapper"> */}
                <Grid item xs={12} md={6}>
                  {" "}
                  <Input
                    label={t("First Name")}
                    placeholder={t("First Name")}
                    name="firstName"
                    errors={methods.formState.errors}
                    register={register}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {" "}
                  <Input
                    label={t("Email")}
                    placeholder={t("Email")}
                    name="email"
                    errors={methods.formState.errors}
                    register={register}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {" "}
                  <Input
                    label={t("Password")}
                    placeholder={t("Password")}
                    name="password"
                    errors={methods.formState.errors}
                    register={register}
                    disabled={disabled}
                    //  className="password-input"
                  />
                  <FormControlLabel
                    sx={{
                      display: "block",
                      width: "20%",
                    }}
                    control={
                      <Switch
                        checked={disabled}
                        onChange={() => setDisabled(!disabled)}
                        style={{ color: "#6932fa" }}
                        name="disabled"
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  {" "}
                  <Input
                    label={t("Last Name")}
                    placeholder={t("Last Name")}
                    name="lastName"
                    errors={methods.formState.errors}
                    register={register}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {" "}
                  <label style={{ marginTop: "12px" }}>
                    {t("Phone Number")}
                  </label>
                  <Controller
                    control={methods.control}
                    name="phone"
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <>
                        <MuiPhoneNumber
                          style={{ marginTop: "16px" }}
                          variant="outlined"
                          defaultCountry="tn"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          margin="dense"
                          name="phone"
                          value={
                            "+216" + methods.formState?.defaultValues?.phone
                          }
                          onChange={(e) => {
                            field.onChange(
                              e
                                ?.split(" ")
                                .slice(1, e?.split(" ").length)
                                .join("")
                            );
                          }}
                          InputProps={{
                            style: {
                              borderRadius: "8px",
                              backgroundColor: "white",
                              height: "45.5px",
                            },
                          }}
                          error={
                            Object?.keys(methods.formState.errors)?.includes(
                              "phone"
                            )
                              ? true
                              : false
                          }
                        />
                        {Object?.keys(methods.formState.errors)?.includes(
                          "phone"
                        ) && (
                          <span style={{ color: "red" }}>{error?.message}</span>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {" "}
                  <Select
                    data={
                      departments &&
                      departments?.map((department) => {
                        return {
                          value: department?._id,
                          label: department?.name,
                        };
                      })
                    }
                    name="department"
                    label={t("Department")}
                    defaultValue={
                      data?.department?.name || t("Select Department")
                    }
                    errors={methods.formState.errors}
                    control={methods.control}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  {" "}
                  <Select
                    data={
                      roles &&
                      roles?.map((role) => {
                        return {
                          value: role?._id,
                          label: role?.code,
                        };
                      })
                    }
                    name="role"
                    label={t("Role")}
                    defaultValue={data?.role?.code || t("Select Role")}
                    errors={methods.formState.errors}
                    control={methods.control}
                  />
                </Grid>
                <Grid item xs={12} md={12} style={{ paddingLeft: "33px" }}>
                  <FormControlLabel
                    className="checkbox-label"
                    name="emailNotif"
                    control={
                      <Checkbox
                        checked={watch()?.emailNotif}
                        name="emailNotif"
                        value="recieve notification"
                        color="primary"
                        onChange={(e) => {
                          methods.setValue("emailNotif", e.target.checked);
                          // setChecked(e.target.checked);
                        }}
                        sx={{
                          color: "#EBEBEB",
                          "&.Mui-checked": {
                            color: "#6932FA",
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: 35,
                            borderRadius: "20%",
                          },
                        }}
                        // inputProps={{ "aria-label": "controlled" }}
                      />
                    }

                    //  label="You can either Receiving Notification on your email or not"
                  />
                  <span className="receive-notifs">
                    Receiving notifications on your email ?
                  </span>
                </Grid>
              </Grid>
            )}

            <Button
              id="add-operator-btn"
              type="submit"
              disabled={
                (data?.id === "edit-department"
                  ? statusUpdateDepartment
                  : statusUpdateOperator) === "loading" ||
                (data?.id === "edit-department" &&
                  methods.getValues()?.name === data?.name &&
                  methods.getValues()?.description === data?.description)
                //||
                // (data?.id !== "edit-department" && "")
              }
            >
              {(data?.id === "edit-department"
                ? statusUpdateDepartment
                : statusUpdateOperator) === "loading" ? (
                <CircularProgress style={{ color: "white" }} />
              ) : data?.id === "edit-department" ? (
                t("update department")
              ) : (
                t("update operator")
              )}
            </Button>
          </FormProvider>
        </Accordion>
      ) : (
        <MainStyle>
          <Messages user={user} firstMessage={data?.client} />
          <ReplyZone user={user} firstMessage={data?.client} />
        </MainStyle>
      )}
    </Drawer>
  );
};

export default AddOperator;
